import { useEffect, useState } from "react";
import AddLanguage from "./AddLanguage";
import Loader from "../../Loader/Loader";
import { languagestatus, language } from "../../service/Mainservice";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Snackbar } from "@mui/material";
import { Add } from "@mui/icons-material";
import Switch from "@mui/material/Switch";

const Language = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [islanguage, setislanguage] = useState(false);
  const [open, setOpen] = useState(false);
  const [editdata, seteditdata] = useState();
  const [checked, setChecked] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getData = async () => {
    try {
      const response = await language();
      console.log(response.data);
      setData(response.data.data);
      setloading(false);
      const initialCheckedState = response.data.data.map(
        (item) => item.is_active
      );
      setChecked(initialCheckedState);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  const lngstatus = async (e, index, id) => {
    try {
      const response = await languagestatus(id, checked[index]);
      setloading(false);
      setOpen(true);
      setChecked({ ...checked, [index]: !e.target.checked });
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const editlng = async (item) => {
    setislanguage(true);
    seteditdata(item);
  };
  const addlng = async (item) => {
    setislanguage(true);
    seteditdata();
  };
  return (
    <>
      <div className="container1">
        <h1 className="data_heading">Language Management </h1>
        <div className="Add_button" onClick={addlng}>
          <Add />
          Add Language
        </div>
        <AddLanguage
          islanguage={islanguage}
          setislanguage={setislanguage}
          data={editdata}
          getData={getData}
        />
      </div>
      <div className="container1">
        <table className="data_table" style={{ marginTop: "15px" }}>
          <thead>
            <tr>
              <th>SL</th>
              <th>Date Created</th>
              <th>Language Image</th>
              <th>Language Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.date_created}</td>
                <td>
                  <img
                    src={item.image}
                    alt="language"
                    className="interestlist_img"
                  />
                </td>
                <td>{item.name}</td>
                <td>
                  {checked[index] ? (
                    <span className="complete_data">Active</span>
                  ) : (
                    <span className="cancel_data">Deactive</span>
                  )}
                </td>
                <td className="language_actions">
                  <EditIcon onClick={() => editlng(item)} />
                  <Switch
                    checked={checked[index]}
                    onChange={(e) => lngstatus(e, index, item.id)}
                    inputProps={{ "aria-label": "controlled" }}
                    color="warning"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <></>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          Successfully
        </Alert>
      </Snackbar>
      {loading && <Loader />}
    </>
  );
};

export default Language;
