import CryptoJS from "crypto-js";

var key = CryptoJS.enc.Utf8.parse("5f6edf8aa1d980f3d508e0cfc5c41573");
var iv = CryptoJS.enc.Utf8.parse("2024021331247814");

export default function decryptString(string) {
  let decrypted = CryptoJS.AES.decrypt(string, key, {
    mode: CryptoJS.mode.CFB,
    iv: iv,
    padding: CryptoJS.pad.NoPadding,
  });
  console.log(decrypted.toString(CryptoJS.enc.Utf8));
  return decrypted.toString(CryptoJS.enc.Utf8);
}
