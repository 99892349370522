import React from "react";
import MainLayout from "../Layout/MainLayout";
import Editprofile from "../component/Profile/EditProfile";
import Changepassword from "../component/Profile/ChangePassword";
import Profile from "../component/Profile/Profile";
import Dashboard from "../component/Dashboard/Dashboard";
import Interests from "../component/Interest/Interest";
import UserList from "../component/User/User";
import UserDetails from "../component/User/UserDetails";
import MatchesList from "../component/Matches/Matcheslist";
import Terms from "../component/Terms&Condition";
import Privacy from "../component/PrivacyPolicy";
import LikeList from "../component/User's Like/Like";
import FavoriteList from "../component/Favorite/Favoritelist";
import PushNotification from "../component/Notification/PushNotification";
import About from "../component/About Us";
import ProtectedRoute from "../Protected Routes/ProtectedRoute";
import Language from "../component/language";
import ReportList from "../component/Report";
import ReportDetails from "../component/Report/ReportDetails";
import ContactList from "../component/Contact Us";
import ContactDetails from "../component/Contact Us/ContactDetails";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/Dashboard",
      element: (
        // <Suspense fallback={<div>Loading...</div>}>
        <Dashboard />
        // </Suspense>
      ),
    },
    {
      path: "/Profile",
      element: <Profile />,
    },
    {
      path: "/EditProfile",
      element: <Editprofile />,
    },
    {
      path: "/ChangePassword",
      element: <Changepassword />,
    },
    {
      path: "/User_Details_Matches",
      element: <MatchesList />,
    },
    {
      path: "/User_Details_Likes",
      element: <LikeList />,
    },
    {
      path: "/User_Details_Favorite",
      element: <FavoriteList />,
    },
    {
      path: "/User_List",
      element: <UserList />,
    },

    {
      path: "/User_Details",
      element: <UserDetails />,
    },
    {
      path: "/Report_List",
      element: <ReportList />,
    },
    {
      path: "/Report_Details",
      element: <ReportDetails />,
    },
    {
      path: "/ContactUs_List",
      element: <ContactList />,
    },
    {
      path: "/ContactUs_Details",
      element: <ContactDetails />,
    },
    {
      path: "/Push_Notification",
      element: <PushNotification />,
    },
    {
      path: "/Interests_Managment",
      element: <Interests />,
    },
    {
      path: "/Language_Managment",
      element: <Language />,
    },

    {
      path: "/About_Us",
      element: <About />,
    },
    {
      path: "/Privacy_Policy",
      element: <Privacy />,
    },
    {
      path: "/TermsofService",
      element: <Terms />,
    },
  ],
};

const MainRoutesWithProtection = {
  element: <MainLayout />,
  children: MainRoutes.children.map((route) => ({
    ...route,
    element: <ProtectedRoute>{route.element}</ProtectedRoute>,
  })),
};

export default MainRoutesWithProtection;
