import { useState } from "react";
import { useEffect } from "react";

import Loader from "../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { reportdetails } from "../../service/Mainservice";
import "./ReportDetails.css";
const ReportDetails = () => {
  const [loading, setloading] = useState(true);
  const [data, setData] = useState(true);
  const Navigate = useNavigate();
  const location = useLocation();

  const id = location.state.id;

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);
  const getData = async (id) => {
    try {
      const response = await reportdetails(id);
      console.log(response.data.data);
      setData(response.data.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        Navigate("/");
      }
    }
  };
  return (
    <>
      <h1 className="userdetails_heading">Report Details </h1>
      <div className="userdetails">
        <div className="userdetails_down">
          <p className="userdata">
            Date Created: <span>{data.report_date_created}</span>
          </p>

          <p className="userdata">
            Read:{"  "}
            {data.report_is_read ? (
              <span className="complete_data">Yes</span>
            ) : (
              <span className="cancel_data">No</span>
            )}
          </p>

          <p className="userdata">
            Reply:{"  "}
            {data.report_is_reply ? (
              <span className="complete_data">Yes</span>
            ) : (
              <span className="cancel_data">No</span>
            )}
          </p>
          {data.report_is_reply ? (
            <>
              <p className="userdata">
                Reply Date: <span>{data.report_reply_date}</span>
              </p>
              <p className="userdata">
                Reply Message: <span>{data.report_reply_msg}</span>
              </p>
            </>
          ) : (
            ""
          )}
          <p className="userdata">
            Message: <span>{data.report_message}</span>
          </p>
        </div>
        <div className="userdetails_down">
          <h1 className="userdetails_title">Reported User</h1>
          <p className="userdata">
            Name: <span>{data.reported_user_full_name}</span>
          </p>

          <p className="userdata">
            Phone Mumber:{" "}
            <span>
              +
              {data.reported_user_country_code +
                data.reported_user_phone_number}
            </span>
          </p>
          <p className="userdata">
            Email: <span>{data.reported_user_email}</span>
          </p>
          <p className="userdata">
            Gender:{" "}
            <span>{data.reported_user_gender === 1 ? "Male" : "Female"}</span>
          </p>
        </div>
        <div className="userdetails_down">
          <h1 className="userdetails_title">Reporter User</h1>
          <p className="userdata">
            Name: <span>{data.reporter_user_full_name}</span>
          </p>

          <p className="userdata">
            Phone Mumber:{" "}
            <span>
              +
              {data.reporter_user_country_code +
                data.reporter_user_phone_number}
            </span>
          </p>
          <p className="userdata">
            Email: <span>{data.reporter_user_email}</span>
          </p>
          <p className="userdata">
            Gender:{" "}
            <span>{data.reporter_user_gender === 1 ? "Male" : "Female"}</span>
          </p>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};
export default ReportDetails;
