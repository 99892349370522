import { useEffect, useState } from "react";
import AddIntrests from "./AddInterests";
import Loader from "../../Loader/Loader";
import { deleteinterest, interest } from "../../service/Mainservice";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Modal, Snackbar } from "@mui/material";
import { Add } from "@mui/icons-material";

const Intrests = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [isinterest, setisinterest] = useState(false);
  const [open, setOpen] = useState(false);
  const [editdata, seteditdata] = useState();
  const [deletemodal, setdeletemodal] = useState();
  const [deleteid, setdeleteid] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getData = async () => {
    try {
      const response = await interest();
      setData(response.data.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  const suredelete = async (id) => {
    setdeleteid(id);
    setdeletemodal(true);
  };

  const deleteint = async () => {
    try {
      const response = await deleteinterest(deleteid);
      setloading(false);
      getData();
      setOpen(true);
      setdeletemodal(false);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const editint = async (item) => {
    setisinterest(true);
    seteditdata(item);
  };
  const addint = async () => {
    setisinterest(true);
    seteditdata();
  };
  return (
    <>
      <div className="container1">
        <h1 className="data_heading">Interest Management </h1>
        <div className="Add_button" onClick={addint}>
          <Add />
          Add Interest
        </div>
        <AddIntrests
          isinterest={isinterest}
          setisinterest={setisinterest}
          data={editdata}
          getData={getData}
        />
      </div>
      <div className="container1">
        <table className="data_table" style={{ marginTop: "15px" }}>
          <thead>
            <tr>
              <th>SL</th>
              <th>Date Created</th>
              <th>Interest Image</th>
              <th>Interest Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.date_created}</td>
                <td>
                  <img
                    src={item.image}
                    alt="interest"
                    className="interestlist_img"
                  />
                </td>
                <td>{item.eng_name}</td>
                <td>
                  <EditIcon onClick={() => editint(item)} />
                  <DeleteIcon onClick={() => suredelete(item.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <></>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          Deleted Successfully
        </Alert>
      </Snackbar>
      <Modal
        open={deletemodal}
        onClose={() => setdeletemodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modaldelete">
          <h3>Are you sure want to delete?</h3>
          <button
            className="interest_addbutton"
            onClick={() => setdeletemodal(false)}
          >
            Cancel
          </button>
          <button className="interest_addbutton" onClick={() => deleteint()}>
            Yes
          </button>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default Intrests;
