import React, { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./Interest.css";
import Loader from "../../Loader/Loader";
import { Snackbar } from "@mui/material";
import Modal from "@mui/material/Modal";
import { addinterest, editinterest } from "../../service/Mainservice";
import { useNavigate } from "react-router-dom";

const AddInterests = ({ isinterest, setisinterest, data, getData }) => {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [Interests_name, setInterests_name] = useState("");
  const [preview, setPreview] = useState();
  const [Image, setImage] = useState();
  const [success, setsuccess] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setInterests_name(data ? data.eng_name : "");
    setPreview(data ? data.image : "");
  }, [data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const imagehandle = (e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(file);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      console.log("No file selected");
    }
  };

  const addint = async () => {
    try {
      const api = data ? editinterest : addinterest;
      const response = await api(Interests_name, Image, data?.id);
      console.log(response);
      setsuccess(response.data.message);
      setOpen(true);
      setloading(false);
      getData();
      setInterests_name("");
      setPreview("");
      setImage();
      setisinterest(false);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  return (
    <Modal
      open={isinterest}
      onClose={() => setisinterest(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalinterest">
        <h1 className="data_heading">
          {data ? "Edit Interest" : "Add Interest"}
        </h1>

        <div className="interest_data">
          <input
            type="file"
            id="file"
            accept="image/*"
            onChange={imagehandle}
            name="profile_pic"
            className="addimginterest"
          />
          {preview ? (
            <div className="previewimgint">
              <img src={preview} alt="profile" className="interest_img" />
              <RemoveCircleOutlineIcon
                className="previewint"
                onClick={() => setPreview()}
              />
            </div>
          ) : (
            <label htmlFor="file" className="uploadint">
              <CameraAltIcon />
            </label>
          )}
          <input
            type="text"
            className="inputinterests"
            placeholder="Enter Interest.."
            value={Interests_name}
            onChange={(e) => {
              setInterests_name(e.target.value);
            }}
          />
        </div>
        <button className="interest_addbutton" onClick={addint}>
          {data ? "Update Interest" : "Add Interest"}
        </button>
        {loading && <Loader />}
        <Snackbar
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          message={success}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      </div>
    </Modal>
  );
};
export default AddInterests;
