import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination/pagination";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../Loader/Loader";
import { userlist } from "../../service/Mainservice";
import profile from "../../assets/Ellipse 24 (2).png";
import profile1 from "../../assets/Ellipse 24 (1).png";
import profilepic from "../../assets/Ellipse 24.png";

const FavoriteList = () => {
  const [loading, setloading] = useState();
  const [data, setData] = useState([]);
  const [dummydata, setdummyData] = useState(data);
  const [searchvalue, setsearchvalue] = useState();
  const [pagination, setpagination] = useState({ page: 1, has_next: true });
  const Navigate = useNavigate();

  useEffect(() => {
    // getData(1);

    setdummyData([
      {
        id: 1,
        profile_img: profile,
        name: "Eleanor Pena, 20",
        speak: "English",
        learn: "Hindi,Russion",
      },
      {
        id: 2,
        profile_img: profile1,
        name: "Lottie Glover, 20",
        speak: "Spanish",
        learn: "French",
      },
      {
        id: 3,
        name: "Mildred Butler, 20",
        profile_img: profile,
        speak: "German",
        learn: "English,Spanish",
      },
      {
        id: 4,
        name: "Eleanor Pena, 20",
        profile_img: profile1,
        speak: "English",
        learn: "Spanish",
      },
    ]);
  }, []);
  console.log(dummydata.map((i) => i.full_name));

  const getData = async (page, searchValue) => {
    try {
      const response = await userlist(page, searchValue);

      setData(response.data.data);
      setdummyData(response.data.data);
      setpagination(response.data.pagination);
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        Navigate("/");
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber, searchvalue);
  };
  const handlesearch = async (e) => {
    setsearchvalue(e.target.value);
    getData(1, e.target.value);
  };

  return (
    <>
      <h1 className="userdetails_heading">User Details </h1>
      <div className="userdetails">
        <div className="userdetails_up">
          <div className="userprofile_up">
            <img src={profilepic} alt="img" />
            <h1 className="username">Jenny Wilson, 23</h1>
            <p className="userbio">
              Sed ut pulvinar turpis. Sed pharetra scelerisque tortor, a
              volutpat sapien egestas eu. Sed venenatis ante congue, laoreet mi
              vel, efficitur justo. Integer venenatis. Sed ut pulvinar turpis.
            </p>
            <button
              className="data_button_view"
              onClick={() => Navigate("/User_Details")}
            >
              View All Details
            </button>
          </div>
          <div className="userprofile_down">
            <div
              className="userpost"
              onClick={() => Navigate("/User_Details_Likes")}
            >
              <h1>353</h1>
              <p>Your Likes</p>
            </div>
            <div
              className="userpost"
              onClick={() => Navigate("/User_Details_Favorite")}
            >
              <h1>9</h1>
              <p>Favorite</p>
            </div>
            <div
              className="userpost"
              onClick={() => Navigate("/User_Details_Matches")}
            >
              <h1>226</h1>
              <p>Matches</p>
            </div>
          </div>
        </div>
        <div className="userdetails_down">
          <div className="container1">
            <div className="data_head">
              <h1 className="data_heading">All Favorite</h1>

              <div className="searchicon">
                <SearchIcon />
                <input
                  type="search"
                  placeholder="Search..."
                  id="search"
                  // onKeyDown={handlesearch}
                />
              </div>
            </div>
            <div className="data_table_over">
              <table className="data_table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Speak</th>
                    <th>Learn</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dummydata?.map((item) => (
                    <tr key={item.id}>
                      <td className="data_userdetails">
                        <img
                          src={item.profile_img}
                          alt="profile"
                          className="data_userdetails_img"
                        />
                        {item.name}
                      </td>
                      <td>{item.speak}</td>
                      <td>{item.learn}</td>
                      {/* <td>
                    {item.booking ? (
                      <span className="cancel_data">Pending</span>
                    ) : (
                      <span className="complete_data">Active</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => Navigate("/User_Details")}
                      className="data_button_view"
                    >
                      View
                    </button>
                  </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination pagedata={pagination} onPageChange={handlePageChange} />
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};
export default FavoriteList;
