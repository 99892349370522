import "./Loader.css";
import logo from "../assets/App Icon.png";

const Loader = () => (
  <div className="loaderdiv">
    <img src={logo} />
  </div>
);

export default Loader;
